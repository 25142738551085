/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('bootstrap');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//Vue.component('example', require('./components/Example.vue'));

// const app = new Vue({
//     el: '#app'
// });

(function ($) {
  $.fn.datepicker.dates["it"] = {
    days: [
      "Domenica",
      "Lunedì",
      "Martedì",
      "Mercoledì",
      "Giovedì",
      "Venerdì",
      "Sabato",
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    daysMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
    months: [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ],
    monthsShort: [
      "Gen",
      "Feb",
      "Mar",
      "Apr",
      "Mag",
      "Giu",
      "Lug",
      "Ago",
      "Set",
      "Ott",
      "Nov",
      "Dic",
    ],
    today: "Oggi",
    monthsTitle: "Mesi",
    clear: "Cancella",
    weekStart: 1,
    format: "dd/mm/yyyy",
  };

  
    

})(jQuery);
$(function () {

    if ($("ul.cb-slideshow").length > 0) {
      //   console.log($("#wrapper_Content").outerHeight());
      $("ul.cb-slideshow").css("height", $("#wrapper_Content").outerHeight());
    }
    $("#new_functions_popup .block_new_functions_popup").click(function (e) {
      // e.preventDefault();

      localStorage.setItem("alert-box", "closed");

      // $("#new_functions_popup").modal("hide");
    });
});
